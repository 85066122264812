.hero-section-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/HeroSection/Hero-section-background-img.jpg");
    background-position: center;
    background-size: cover;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
}

@media screen and (max-width: 520px) {
    .hero-section-container {
        height: 70vh;
    }
}