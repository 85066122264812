body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #388EF0;
  --primary-color-light: #e6f1ff;
  --primary-color-dark: #023e83;
}

.page-content {
  padding: 12px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.washUp-Button-Gradient {
  background: #388EF0 !important;
  background: linear-gradient(90deg,
      #388EF0 0%,
      #000000 150%) !important;
}